import { useState, useCallback } from 'react';
import { fetchAuthSession } from 'aws-amplify/auth';
import { listS3Files, getObjectUri, getS3Client } from '../services/s3Service';

export const useS3Files = () => {
  const [files, setFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const refreshFiles = useCallback(async () => {
    setIsLoading(true);
    try {
      const session = await fetchAuthSession();
      if (!session?.tokens?.accessToken?.payload) {
        throw new Error('No valid session');
      }

      const groups = session.tokens.accessToken.payload['cognito:groups'] || [];
      const s3Client = await getS3Client();
      
      const response = await listS3Files();
      if (!response?.Contents) {
        setFiles([]);
        return;
      }

      const items = response.Contents.map(item => ({
        key: item.Key,
        size: item.Size,
        lastModified: new Date(item.LastModified)
      }));

      const accessibleFiles = items.filter(file => 
        groups.some(group => file.key.startsWith(`downloads/${group}/`))
      );

      // Get signed URLs
      const filesWithUrls = await Promise.all(
        accessibleFiles.map(async (file) => {
          try {
            const uri = await getObjectUri(file.key, s3Client);
            return { ...file, uri };
          } catch (err) {
            console.error(`Error getting URL for ${file.key}:`, err);
            return { ...file, uri: '' };
          }
        })
      );

      setFiles(filesWithUrls);
    } catch (error) {
      console.error('Error listing files:', error);
      setFiles([]);
    } finally {
      setIsLoading(false);
    }
  }, []);

  return { files, isLoading, refreshFiles };
};
