import React, { useEffect } from 'react';
import { Amplify } from 'aws-amplify';
import { Authenticator, useAuthenticator } from '@aws-amplify/ui-react';
import { RefreshIcon } from '@heroicons/react/outline';
import { Header } from './components/layout/Header';
import { FileList } from './components/files/FileList';
import { AuthHeader, AuthFooter } from './components/auth/AuthLayout';
import { useS3Files } from './hooks/useS3Files';
import awsconfig from './config/awsconfig';

import '@aws-amplify/ui-react/styles.css';
import './styles/Custom.css';

// Configure Amplify.. this should be handled by SSM
Amplify.configure(awsconfig);

const MainContent = () => {
  const { authStatus } = useAuthenticator();
  const { files, isLoading, refreshFiles } = useS3Files();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      refreshFiles();
    }
  }, [authStatus, refreshFiles]);

  return (
    <div>
      <Header onRefresh={refreshFiles} />
      {isLoading ? (
        <div className="max-w-7xl mx-auto py-12 px-4 text-center">
          <RefreshIcon className="mx-auto h-8 w-8 text-gray-400 animate-spin" />
          <h3 className="mt-2 text-gray-300">Loading files...</h3>
        </div>
      ) : (
        <FileList files={files} />
      )}
    </div>
  );
};

const App = () => {
  return (
    <div className="min-h-screen bg-gray-800 dark">
      <Authenticator
        hideSignUp={true}
        components={{
          Header: AuthHeader,
          Footer: AuthFooter
        }}
      >
        <MainContent />
      </Authenticator>
    </div>
  );
};

export default App;
