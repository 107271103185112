import { S3Client, ListObjectsV2Command, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { fetchAuthSession } from 'aws-amplify/auth';

const BUCKET_NAME = 'data-arcapp-com-au';

export const getS3Client = async () => {
  const session = await fetchAuthSession();
  return new S3Client({
    region: 'ap-southeast-2',
    credentials: session.credentials
  });
};

export const listS3Files = async () => {
  const s3Client = await getS3Client();
  const command = new ListObjectsV2Command({
    Bucket: BUCKET_NAME,
    Prefix: 'downloads/',
    MaxKeys: 1000
  });

  return s3Client.send(command);
};

export const getObjectUri = async (key, s3Client) => {
  const command = new GetObjectCommand({
    Bucket: BUCKET_NAME,
    Key: key
  });
  return getSignedUrl(s3Client, command, { expiresIn: 3600 });
};
