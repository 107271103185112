import { FolderDownloadIcon } from '@heroicons/react/outline';

export const EmptyState = () => (
  <div className="max-w-7xl mx-auto py-12 px-4">
    <div className="text-center border-2 border-dashed border-gray-700 rounded-lg p-12">
      <FolderDownloadIcon className="mx-auto h-12 w-12 text-gray-400" />
      <h3 className="mt-2 text-gray-300">No files available</h3>
    </div>
  </div>
);
