/**
 * Extract environment from file path
 */
export const getEnvironmentFromPath = (path) => {
  const parts = path.split('/');
  return parts[2] || '';
};

/**
 * Extract group from file path
 */
export const getGroupFromPath = (path) => {
  const parts = path.split('/');
  return parts[1] || '';
};

/**
 * Get environment badge color
 */
export const getEnvColor = (env) => {
  switch(env) {
    case 'dev': return 'bg-yellow-900 text-yellow-300';
    case 'test': return 'bg-blue-900 text-blue-300';
    case 'prd': return 'bg-green-900 text-green-300';
    default: return 'bg-gray-900 text-gray-300';
  }
};
