// src/awsconfig.js
const awsconfig = {
  aws_project_region: 'ap-southeast-2',
  aws_cognito_region: 'ap-southeast-2',
  aws_user_pools_id: 'ap-southeast-2_zpJvbRrsH',
  aws_user_pools_web_client_id: '9u70tfop2d7hklf0l6u84djod',
  aws_cognito_identity_pool_id: 'ap-southeast-2:f41780ee-5e46-4b45-8aeb-6627bb5c2936',
  aws_mandatory_sign_in: 'enable',
  aws_user_files_s3_bucket: 'data-arcapp-com-au',
  aws_user_files_s3_bucket_region: 'ap-southeast-2',
  Storage: {
    AWSS3: {
      bucket: 'data-arcapp-com-au',
      region: 'ap-southeast-2',
      identityPoolId: 'ap-southeast-2:f41780ee-5e46-4b45-8aeb-6627bb5c2936',
    },
    customPrefix: {
      public: 'downloads/',
      protected: 'should-not-exist/',
      private: 'should-not-exist/',
    },
  },
};

export default awsconfig;
