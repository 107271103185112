import { Heading } from '@aws-amplify/ui-react';

export const AuthHeader = () => (
  <div className="py-8 px-4">
    <div className="flex flex-col items-center space-y-3">
      <Heading level={3} className="text-3xl font-bold welcome-text">
        ARC Download Service
      </Heading>
      <p className="text-sm text-gray-400">
        Sign in to access your secure downloads
      </p>
    </div>
  </div>
);

export const AuthFooter = () => (
  <div className="py-4 text-center">
    <p className="text-sm text-gray-400">
      © {new Date().getFullYear()} Phoenix Management Services Pty Ltd.
    </p>
  </div>
);
