import { FolderDownloadIcon } from '@heroicons/react/outline';
import { FileItem } from './FileItem';
import { EmptyState } from '../../utils/EmptyState';
import { getGroupFromPath } from '../../utils/path-utils';

export const FileList = ({ files }) => {
  const groupedFiles = files.reduce((acc, file) => {
    const group = getGroupFromPath(file.key);
    if (!acc[group]) acc[group] = [];
    acc[group].push(file);
    return acc;
  }, {});

  if (files.length === 0) {
    return <EmptyState />;
  }

  return (
    <div className="max-w-7xl mx-auto py-6 px-4">
      {Object.entries(groupedFiles).map(([group, groupFiles]) => (
        <div key={group} className="mb-8">
          <div className="flex items-center mb-4">
            <FolderDownloadIcon className="h-5 w-5 text-gray-400 mr-2" />
            <h2 className="text-lg font-semibold text-gray-200 capitalize">
              {group}
              <span className="ml-2 text-sm font-normal text-gray-400">
                ({groupFiles.length} {groupFiles.length === 1 ? 'file' : 'files'})
              </span>
            </h2>
          </div>
          <div className="bg-gray-900 border border-gray-700 rounded-lg overflow-hidden">
            <table className="min-w-full divide-y divide-gray-700">
              <thead>
                <tr>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Name</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Size</th>
                  <th className="px-6 py-3 text-left text-xs font-medium text-gray-400 uppercase">Modified</th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-700">
                {groupFiles.map((file) => (
                  <FileItem key={file.key} file={file} />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ))}
    </div>
  );
};
