import { RefreshIcon, LogoutIcon } from '@heroicons/react/outline';
import { useAuthenticator } from '@aws-amplify/ui-react';

export const Header = ({ onRefresh }) => {
  const { signOut, user } = useAuthenticator();
  const userName = user?.attributes?.name || user?.username || 'Hello, Anon?';

  return (
    <div className="bg-gray-900 border-b border-gray-700">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          <div className="flex items-center space-x-4">
            <h1 className="text-xl font-semibold text-gray-200">
              ARC Download Service
            </h1>
            <span className="text-sm text-gray-400 hidden sm:inline">
              Welcome back, <span className="text-gray-300 font-medium">{userName}</span>
            </span>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={onRefresh}
              className="flex items-center px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            >
              <RefreshIcon className="h-4 w-4 mr-2" />
              Refresh
            </button>
            <button
              onClick={signOut}
              className="flex items-center px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700 transition-colors"
            >
              <LogoutIcon className="h-4 w-4 mr-2" />
              Sign Out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
