import { FolderDownloadIcon, ServerIcon, ClockIcon } from '@heroicons/react/outline';
import { formatFileSize } from '../../utils/formatters';
import { getEnvironmentFromPath, getEnvColor } from '../../utils/path-utils';

export const FileItem = ({ file }) => {
  const fileName = file.key.split('/').pop();
  const env = getEnvironmentFromPath(file.key);
  const envColor = getEnvColor(env);
  
  return (
    <tr className="border-b border-gray-700 hover:bg-gray-800 transition-colors">
      <td className="px-6 py-4">
        <div className="flex flex-col">
          <div className="flex items-center">
            <FolderDownloadIcon className="h-5 w-5 text-gray-400 mr-3" />
            <a
              href={file.uri}
              target="_blank"
              rel="noreferrer"
              className="text-blue-400 hover:text-blue-300 hover:underline"
            >
              {fileName}
            </a>
          </div>
          <div className="mt-1">
            <span className={`px-2 py-1 rounded-full text-xs ${envColor}`}>
              {env.toUpperCase()}
            </span>
          </div>
        </div>
      </td>
      <td className="px-6 py-4 text-gray-300">
        <div className="flex items-center">
          <ServerIcon className="h-4 w-4 text-gray-400 mr-2" />
          {formatFileSize(file.size)}
        </div>
      </td>
      <td className="px-6 py-4 text-gray-300">
        <div className="flex items-center">
          <ClockIcon className="h-4 w-4 text-gray-400 mr-2" />
          {file.lastModified.toLocaleString()}
        </div>
      </td>
    </tr>
  );
};
